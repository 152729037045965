import React, { useEffect, useState } from 'react'
import { Tooltip } from 'reactstrap'
import axios from 'axios'
import Audioplayer from './Audioplayer'
import Loader from './Loader'
import { getDateTime, getDuration, getPhoneNumber } from '../../lib/utilities'

import CONFIG from '../../constants/config.json'
import NewSms from '../CallHistory/NewSms'
import QuickCall from './QuickCall'
import MoveVoiceMail from './MoveVoiceMail'
import './voicemails.css'

const Voicemail = (props) => {
  const {
    loader,
    from,
    to,
    vmboxId,
    mediaId,
    authToken,
    accountId,
    voicemailDelete,
    history,
    audioPlayer,
    audioPlay,
    audioId,
    audioPlayerEnd,
    folder,
    voicemailChangeStatus,
    selected,
    selectRowToUpdate,
    timestamp,
    length,
  } = props

  const URL = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmboxId}/messages/${mediaId}/raw?authToken=${authToken}`

  const [transcriptionData, setTranscriptionData] = useState(null)
  const [openTranscription, setOpenTranscription] = useState(false)
  const [openTooltip, setOpenTooltip] = useState({
    transcript: false,
    play: false,
    download: false,
    trash: false,
    movefile: false,
  })

  const toggleTooltip = (key) => {
    switch (key) {
      case 'transcript':
        setOpenTooltip({ ...openTooltip, transcript: !openTooltip.transcript })
        return
      case 'play':
        setOpenTooltip({ ...openTooltip, play: !openTooltip.play })
        return
      case 'download':
        setOpenTooltip({ ...openTooltip, download: !openTooltip.download })
        return
      case 'trash':
        setOpenTooltip({ ...openTooltip, trash: !openTooltip.trash })
        return
      case 'movefile':
        setOpenTooltip({ ...openTooltip, movefile: !openTooltip.movefile })
        return
      default:
        return
    }
  }

  const showTranscription = () => {
    setOpenTranscription(!openTranscription)
  }

  useEffect(() => {
    if (accountId && vmboxId && mediaId) {
      const transcriptionURI = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmboxId}/messages/${mediaId}`
      ;(async function () {
        try {
          const result = await axios.get(transcriptionURI)
          if (result?.status === 200) {
            setTranscriptionData(result.data.data.transcription)
          } else {
            setTranscriptionData(null)
          }
        } catch (error) {
          setTranscriptionData(null)
        }
      })()
    }
  }, [accountId, vmboxId, mediaId])

  return (
    <>
      <div
        className={
          audioPlay && audioId !== mediaId ? 'voicemail-row disabledbutton voicemail-row-active' : 'voicemail-row'
        }
      >
        <div className="col-md-1 d-flex align-items-center justify-content-between">
          <input
            type="checkbox"
            className="multi-select-control-input mr-2"
            id="selectId"
            checked={selected[mediaId] === true}
            onChange={() => selectRowToUpdate(mediaId)}
          />
          <div className="d-flex justify-content-between align-items-center">
            {loader ? (
              <Loader loader={loader}></Loader>
            ) : folder === 'new' ? (
              <span className="newstatus" onClick={() => voicemailChangeStatus('saved', vmboxId, mediaId)}>
                New
              </span>
            ) : folder === 'saved' ? (
              <span className="listenedstatus" onClick={() => voicemailChangeStatus('new', vmboxId, mediaId)}>
                Listened
              </span>
            ) : folder === 'deleted' ? (
              <span className="deletedstatus">Deleted</span>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="col-md-2">
          <div className="name">{getDateTime(timestamp).date}</div>
          <div className="number">{getDateTime(timestamp).time}</div>
        </div>
        <div className="col-md-2">
          <div className="name"> {getPhoneNumber(from.split('@')[0])}</div>
        </div>
        <div className="col-md-2">
          <div className="name"> {getPhoneNumber(to.split('@')[0])}</div>
        </div>
        <div className="col-md-1">
          <div className="name"> {getDuration(length / 1000)}</div>
        </div>
        <div className="col-md-4">
          {audioPlay && audioId === mediaId ? (
            <div className="row" id={`play-btn-${mediaId}`}>
              <div className="col-md-10">
                <Audioplayer props={props} />
              </div>
              <div className="col-md-2">
                <button className="audio-close" onClick={() => audioPlayerEnd(mediaId, vmboxId, mediaId, folder)}>
                  Close
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center w-100 mailchange">
              {(getPhoneNumber(from.split('@')[0]).split(' ')[1]?.split(' ').join('').length === 10 ||
                getPhoneNumber(from.split('@')[0]).split(' ')[0] === '+1') && (
                <NewSms
                  history={history}
                  phoneNumber={getPhoneNumber(from.split('@')[0])}
                  toNumber={from.split('@')[0]}
                  fromNumber={to.split('@')[0]}
                />
              )}
              <QuickCall
                history={history}
                phoneNumber={getPhoneNumber(from.split('@')[0])}
                toNumber={from.split('@')[0]}
                fromNumber={to.split('@')[0]}
              />
              {transcriptionData && (
                <>
                  <div className="btn btn-light" id={`transcript-btn-${mediaId}`} onClick={showTranscription}>
                    <i className={`fas fa-eye${openTranscription ? '-slash' : ''}`}></i>
                  </div>
                  <Tooltip
                    placement="top"
                    isOpen={openTooltip.transcript}
                    autohide={false}
                    target={`transcript-btn-${mediaId}`}
                    toggle={() => toggleTooltip('transcript')}
                  >
                    Transcript
                  </Tooltip>
                </>
              )}
              <div className="btn btn-light" id={`play-btn-${mediaId}`} onClick={() => audioPlayer(mediaId)}>
                <i className="fas fa-play-circle"></i>
                <Tooltip
                  placement="top"
                  isOpen={openTooltip.play}
                  autohide={false}
                  target={`play-btn-${mediaId}`}
                  toggle={() => toggleTooltip('play')}
                >
                  Play
                </Tooltip>
              </div>

              <a href={URL} className="btn btn-light" id={`download-btn-${mediaId}`}>
                <i className="fas fa-file-download"></i>
                <Tooltip
                  placement="top"
                  isOpen={openTooltip.download}
                  autohide={false}
                  target={`download-btn-${mediaId}`}
                  toggle={() => toggleTooltip('download')}
                >
                  Download
                </Tooltip>
              </a>
              <MoveVoiceMail
                history={history}
                vmboxId={vmboxId}
                mediaId={mediaId}
                accountId={accountId}
                voicemailDelete={voicemailDelete}
              />
              <div
                className="btn btn-light"
                id={`trash-btn-${mediaId}`}
                onClick={() => voicemailDelete(vmboxId, mediaId)}
              >
                <i class="fas fa-trash-alt"></i>
                <Tooltip
                  placement="top"
                  isOpen={openTooltip.trash}
                  autohide={false}
                  target={`trash-btn-${mediaId}`}
                  toggle={() => toggleTooltip('trash')}
                >
                  Delete
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
      {openTranscription && (
        <div className="voicemail-row transcription-row">
          <div className="col-md-12 ">
            {transcriptionData.text ? transcriptionData.text : 'There are no transcriptions'}
          </div>
        </div>
      )}
    </>
  )
}

export default Voicemail
