import React from 'react'
import { Tooltip, Card, CardBody, Badge } from 'reactstrap'
import { phoneNumFormat, getMemberName, getCompanyName, getProfileAvatar, truncateString } from '../../lib/utilities'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'

import { getNomalConversations } from '../../actions/message.action'
import * as CONSTS from '../../constants/const'
import DropMenu from './DropMenu'
import silhoutte from '../../asset/media/img/silhoutte.png'
import './message.css'

const NormalConversations = (props) => {
  const {
    filteredNormlMembers,
    setSelected,
    showInfoPane,
    isContactInformation,
    setMemberIformation,
    setMemNumber,
    userNumber,
    checkAssignConversation,
    checkNotifies,
    isToolTipOpen,
    toggleTooltip4,
    deleteFavorite,
    addFavorite,
    assignConversationShowPopup,
    checkAssignConversationLength,
    adminPhoneNum,
    deleteHistory,
    isContactDialog,
    normalTotalCount,
    searchNumber,
  } = props
  const dispatch = useDispatch()
  const { numbers, userName, favoriteConversations, normalConversations, normalPagniationNum } = useSelector(
    (state) => state.message,
  )
  const handleNormalPageClick = async (data) => {
    const { selected } = data
    await dispatch({
      type: CONSTS.GET_NORMAL_CONVERSATIONS_PAGINATION_NUMS,
      payload: selected,
    })
    await dispatch(getNomalConversations(numbers.savedNumber, userName.userEmail, searchNumber))
  }

  return (
    <>
      <ul className="list-group list-group-flush">
        {filteredNormlMembers &&
          filteredNormlMembers &&
          filteredNormlMembers.map((member, i) => {
            const memberName = getMemberName(member)
            const companyName = getCompanyName(member)
            const avatar = getProfileAvatar(member)

            return (
              <Card key={i} className="chat-name">
                <CardBody>
                  <li
                    key={i}
                    onClick={() => {
                      setSelected(member)
                      showInfoPane ? isContactInformation(true, member) : isContactInformation(false, member)
                      setMemberIformation(member)
                      setMemNumber(member.memberNum, member.distribution, member.distributionId, member.subscribe)
                    }}
                    className={`list-group-item ${member.memberNum === userNumber ? 'open-chat' : ''} ${
                      checkAssignConversation(member.memberNum) && !checkNotifies(member.memberNum)
                        ? 'list-chat'
                        : !checkNotifies(member.memberNum) || checkAssignConversation(member.memberNum)
                        ? 'list-count-chat'
                        : ''
                    }`}
                  >
                    <figure className={`avatar ${avatar ? 'profile' : 'icon'}`}>
                      {member.distribution ? (
                        <i className="fas fa-user-friends conversation-font-size" />
                      ) : (
                        <>
                          <img alt="Avatr" src={avatar || silhoutte} />
                        </>
                      )}
                    </figure>

                    <div className="users-list-body">
                      <span
                        onClick={() =>
                          setMemNumber(member.memberNum, member.distribution, member.distributionId, member.subscribe)
                        }
                      >
                        {member?.contacts.length === 0 ? (
                          <h5>{phoneNumFormat(member.memberNum)}</h5>
                        ) : (
                          <span>
                            <div className="chat-labelName">
                              <h5>
                                {!member.distribution ? truncateString(memberName, 17) : truncateString(memberName, 14)}
                              </h5>
                              <h5 id={`numbercount-${i}`}>
                                {member?.contacts.length > 1 && `or  ${member?.contacts.length - 1} other`}{' '}
                              </h5>
                              <Tooltip
                                placement="bottom"
                                isOpen={isToolTipOpen(`numbercount-${i}`)}
                                autohide={false}
                                target={`numbercount-${i}`}
                                toggle={() => toggleTooltip4(`numbercount-${i}`)}
                              >
                                {member?.contacts.map((data, i) => (
                                  <div key={i}>
                                    {data.labelName !== memberName && (data.labelName || data.company)}{' '}
                                  </div>
                                ))}
                              </Tooltip>
                            </div>
                            {!member.distribution && <h6>{companyName}</h6>}
                            {!member.distribution && <h6>{phoneNumFormat(member.memberNum)}</h6>}
                          </span>
                        )}
                        {!member.distribution &&
                          (member.isFavorite ? (
                            <i
                              onClick={() => deleteFavorite(member.memberNum)}
                              className="far fa-star chat-favorite-icon"
                            ></i>
                          ) : (
                            <i
                              onClick={() => addFavorite(member.memberNum)}
                              className="far fa-star chat-important-icon"
                            ></i>
                          ))}
                        {checkAssignConversation(member.memberNum) > 0 && (
                          <Badge className="assign-badge" onClick={() => assignConversationShowPopup(member)} pill>
                            Assigned to {checkAssignConversationLength(member.memberNum)}{' '}
                            {`${checkAssignConversationLength(member.memberNum) === 1 ? 'person' : 'people'}`}
                          </Badge>
                        )}
                        {normalConversations.notifies &&
                          normalConversations.notifies.map((notify, i) => {
                            if (
                              notify.number === member.memberNum ||
                              notify.number === memberName ||
                              notify.number === member.name
                            ) {
                              return (
                                <div key={i} id="new-msg" className="users-list-action">
                                  <div className="new-message-count">{notify.newMsg}</div>
                                </div>
                              )
                            }
                            return null
                          })}
                      </span>
                    </div>
                  </li>
                  {(!member.distribution || member.distribution === undefined) && (
                    <div className="users-list-action">
                      <div className="action-toggle">
                        <DropMenu
                          tab="favTab1"
                          toNumber={member.memberNum}
                          readConversation={member.readConversation}
                          fromNumber={adminPhoneNum}
                          email={userName.userEmail}
                          deleteHistory={deleteHistory}
                          contactID={member.contactID}
                          labelName={memberName}
                          favoriteMem={favoriteConversations.favConversationsList}
                          member={member}
                          isContactDialog={isContactDialog}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            )
          })}
      </ul>
      {normalTotalCount > 1 && (
        <ReactPaginate
          previousLabel={'⟨'}
          nextLabel={'⟩'}
          // breakLabel={'...'}
          forcePage={normalPagniationNum}
          breakClassName={'break-me'}
          pageCount={normalTotalCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={2}
          onPageChange={handleNormalPageClick}
          containerClassName={'con-pagination'}
          activeClassName={'active'}
        />
      )}
    </>
  )
}

export default NormalConversations
