import React from 'react'

import ScheduleSMS from './ScheduleSMS'

const Footer = ({
  imageUpload,
  uploadInput,
  values,
  handleValues,
  handleKeyDown,
  updateValues,
  sendingMessage,
  fromNumber,
  adminUseremail,
  uploadImgName,
  favTab,
}) => {
  return (
    <div className="chat-footer">
      <div className="chat-footer-form">
        <div className="chat-image-upload">
          <label id="#bb">
            <i className="fas fa-paperclip"></i>
            <input
              type="file"
              ref={uploadInput}
              onChange={imageUpload}
              accept="image/*|audio/*|video/*|application/*"
            />
          </label>
        </div>
        <div id="w-input-container">
          <div className="w-input-text-group">
            <input
              id="msgText"
              name="msgText"
              value={values.msgText}
              onChange={(e) => handleValues(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder="Type a message here…"
            />
          </div>
        </div>
        <div className="form-buttons">
          <button
            className="btn btn-primary btn-rounded btn-sm send-btn"
            onClick={sendingMessage}
            disabled={values.msgText.length === 0}
          >
            <i className="fas fa-paper-plane" />
          </button>
          <ScheduleSMS
            toNumber={values.phoneNum}
            fromNumber={fromNumber}
            message={values.msgText}
            sender={adminUseremail}
            media={uploadImgName}
            updateValues={updateValues}
            values={values}
            tab={favTab}
          />
        </div>
      </div>
      <div className="message-action uplaodmsgtext">{uploadImgName}</div>
    </div>
  )
}

export default Footer
